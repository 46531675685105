import React from "react";
import {MailOpenIcon} from "@heroicons/react/outline";
import { useForm } from '@formspree/react';
import Spinner from "./spinner";

export default function ContactForm() {
  const [state, handleSubmit] = useForm("xrgrqvlg");

  return (
    <section className="bg-primary" id="contact-form">
      <div className="p-4 md:p-12 grid grid-cols-1 md:grid-cols-2 items-center gap-10">
        <div>
          <p className="max-w-sm font-bold text-4xl text-white text-center mx-auto">
            Scrivimi per prenotare un incontro o chiedere informazioni
          </p>
        </div>
        <div>
          {!state.succeeded && (
            <form className="max-w-sm mx-auto" onSubmit={handleSubmit}>
              <label className="block text-white">
                <span className="font-bold">Email</span>
                <input type="email" required name="email" className="mt-0 block w-full px-0.5 border-0 border-b-2 border-white focus:ring-0 focus:border-fourth bg-transparent" />
              </label>
              <label className="block text-white mt-5">
                <span className="font-bold">Telefono (facoltativo)</span>
                <input name="phone" className="mt-0 block w-full px-0.5 border-0 border-b-2 border-white focus:ring-0 focus:border-fourth bg-transparent" />
              </label>
              <label className="block text-white mt-5">
                <span className="font-bold">Messaggio</span>
                <textarea
                  required
                  name="message"
                  className="mt-0 block w-full px-0.5 border-0 border-b-2 border-white focus:ring-0 focus:border-fourth bg-transparent"
                  rows="2"
                />
              </label>
              <button type="submit"
                disabled={state.submitting}
                className="flex justify-center align-middle mx-auto mt-8 px-3 py-1 text-primary bg-fourth rounded-sm w-32 text-center mt-3 disabled:opacity-50"
              >
                Invia {state.submitting && <Spinner />}
              </button>
            </form>
          )}
          {state.succeeded && (
            <div className="text-center text-2xl text-white flex flex-col items-center max-w-xs mx-auto">
              <MailOpenIcon className="h-8 w-8 text-fourth" />
              <p>Grazie di avermi contattato, riceverai presto una risposta!</p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
